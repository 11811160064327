import React from 'react';
import HeaderThree from '../common/header/HeaderThree';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
import ProjectTwo from '../component/project/ProjectTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import BrandOne from '../component/brand/BrandOne';
import ProjectThree from '../component/project/ProjectThree';
import TestimonialTwo from '../component/testimonial/TestimonialTwo';
import FooterTwo from '../common/footer/FooterTwo';
import FormThree from '../component/contact/FormThree';
import ServicePropOne from '../component/service/ServicePropOne';
import PricingOne from '../component/pricing/PricingOne';
import FooterOne from '../common/footer/FooterOne';



const PersonalPortfolio = () => {

    return (
        <>
        <SEO title="Home"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderThree />
            <BannerThree />
            <div className="section section-padding">
                <div className="container">
                <SectionTitle 
                    subtitle="Prepare Smarter, Not Harder."
                    title="Why we are <br> the best choice"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div>
            {/* <ProjectTwo /> */}
            {/* <ProjectThree /> */}

            {/* <BrandOne /> */}
            <TestimonialTwo />
            <div className="section bg-color-light section-padding">
                <div className="container">
                    <SectionTitle 
                        subtitle="Pricing Plan"
                        title="Our Affordable Plans"
                        description="
                        Discover the perfect Edfrica pricing package for your study needs and goals.
                        ."
                        textAlignment=""
                        textColor=""
                    />
                    <PricingOne />
                </div>
                <ul className="list-unstyled shape-group-3">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>
                </ul>
            </div>

            <div className="section-padding bg-color-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="Got A Question?"
                                title="We're here to help! Reach out to us "
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            <div className="address-list">
                                <div className="address">
                                <h6 className="title">Mail</h6>
                                <p>info@edfrica.com</p>
                                </div>

                                <div className="address">
                                <h6 className="title">Phone</h6>
                                <p>+223 302 456 179</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                <h3 className="title">Send An Email</h3>
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterOne />

        </main>
        </>
    )
}

export default PersonalPortfolio;

