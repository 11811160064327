import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                {/* <li><a href="https://blog.edfrica.com" target="_blank" rel="noopener noreferrer">Our Blog</a></li> */}
                {/* <li><a href="https://help.edfrica.com" target="_blank" rel="noopener noreferrer">Help</a></li> */}
                {/* <li><Link to={process.env.PUBLIC_URL + "#"}>FAQs</Link></li> */}
                <li><a href="https://accounts.edfrica.com" target="_blank" rel="noopener noreferrer">Account</a></li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>

            </ul>
        </nav>
    )
}

export default Nav;