import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { Link } from 'react-router-dom';
import { FaEnvelopeOpen } from "react-icons/fa";

const Welcome = () => {
    return (
        <>
            <SEO title="Welcome to Edfrica" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <div className="welcome-area onepage-screen-area d-flex align-items-center justify-content-center">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="site-logo mb-4">
                                    <Link to={process.env.PUBLIC_URL + "/"} className="logo-light">
                                        <img src="https://media.licdn.com/dms/image/v2/D4E0BAQFzJQjyoe8tvA/img-crop_100/img-crop_100/0/1728290313697?e=1736380800&v=beta&t=9y0QbX3GijJTKtnZ6nMpgWmD_Ho43AiaqM_2PcNJkFM" alt="Edfrica Logo Light" height={200}/>
                                    </Link>

                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 mx-auto">
                                <div className="content">
                                    <h2 className="title mb-3">Welcome to Edfrica!</h2>
                                    <p className="mb-4">Your email has been successfully confirmed. We're thrilled to have you with us.</p>

                                </div>
                            </div>
                        </div>
                        {/* Footer Links */}
                        <div className="row mt-5">
                            <div className="col-12">
                                <ul className="footer-links list-unstyled d-flex justify-content-center">
                                    <li className="mx-3">
                                        <Link to="/" className="text-decoration-none text-muted">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="mx-3">
                                        <Link to="/contact" className="text-decoration-none text-muted">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li className="mx-3">
                                        <a 
                                            href="https://accounts.edfrica.com" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            className="text-decoration-none text-muted"
                                        >
                                            My Account
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Decorative Shapes */}
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-2">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-28.png"} alt="Bubble" />
                        </li>
                        <li className="shape shape-3">
                            <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                        </li>
                    </ul>
                </div>
            </main>
        </>
    );
}

export default Welcome;
