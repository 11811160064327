import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaLinkedinIn, FaBehance, FaPhone, FaFax } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            {/* <li><a href="https://blog.edfrica.com" target="_blank" rel="noopener noreferrer">Our Blog</a></li> */}
                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
                            {/* <li><a href="https://help.edfrica.com" target="_blank" rel="noopener noreferrer">Help</a></li> */}
                            <li><a href="https://accounts.edfrica.com" target="_blank" rel="noopener noreferrer">Account</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Contact Information</span>
                                    <p>Greenfield Estate <br /> 3AN1</p>
                                </address>
                                <address className="address">
                                    <span className="title">We're Available 24/7. Call Now.</span>
                                    <a href="tel:+233548952019" className="tel"><FaPhone /> (233) 548-952-019</a>
                                    <a href="tel:+233554832032" className="tel"><FaFax /> (233) 055-483-2032</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Find us here</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://facebook.com/"><FaFacebookF /></a>
                                        </li>
                                        <li>
                                            <a href="https://x.com/edfrica_"><FaXTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/103470967"><FaLinkedinIn /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;